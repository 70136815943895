import api from './init';
import returnError from './returnError';

export function companyList(data) {
  let url = `/api/v1/companies.json?page=${data.page}&search=${data.search}&zip=${data.zip}&stars=${data.stars}&classification=${data.classification}&state=${data.state}&all=0`;

  return api
    .get(url, { withCredentials: true })
    .then(response => {
      return response.data
    })
    .catch((error) => {
      console.log(error);
    });
}

export function listCompanies(data) {
  if (data.user === null) { data.user = ''; }
  let url = `/api/v1/companies.json?page=${data.currentPage}&search=${data.search}&all=${data.all}&stars=${data.stars}&user=${data.user}`;
  if (data.limit !== undefined) { url += `&limit=${data.limit}`; }
  return api
    .get(url, { withCredentials: true })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
}

export function listCompaniesForDropDown() {
  return api
    .get("/api/v1/companies.json?page=-1&fields=id,name,as400_id,quotes_no_cc_fee")
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
}

export function getCompany(id) {
  return api
    .get(`/api/v1/companies/${id}.json`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
}

export function saveCompany(data) {
  const url = '/api/v1/companies';
  return api.post(url, data, { withCredentials: true })
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      return returnError();
    });
}

export function updateCompany(id, data) {
  const url = `/api/v1/companies/${id}`;
  return api.patch(url, data, { withCredentials: true })
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      return returnError();
    });
}

export function getActivities(id, data) {
  const url = `/api/v1/companies/${id}/activities?page=${data.page}&at=${data.activityType}`;
  return api.get(url, { withCredentials: true })
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      return returnError();
    });
}

export function saveActivity(id, data) {
  const url = `/api/v1/companies/${id}/activities`;
  return api.post(url, data, { withCredentials: true })
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      return returnError();
    });
}

export function updateActivity(id, activityId, data) {
  const url = `/api/v1/companies/${id}/activities/${activityId}`;
  return api.put(url, data, { withCredentials: true })
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      return returnError();
    })
}

export function getContact(id, companyId) {
  const url = `/api/v1/companies/${companyId}/contacts/${id}`;
  console.log(url);
  return api.get(url)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      return returnError();
    });
}

export function saveContact(id, companyId, isNew, data) {
  let url = `/api/v1/companies/${companyId}/contacts`;
  let method = 'post';
  if (!isNew) {
    method = 'patch';
    url = `/api/v1/companies/${companyId}/contacts/${id}`;
  }

  return api[method](url, data, { withCredentials: true })
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      return returnError();
    });
}

export function deleteContact(id, companyId) {
  const url = `/api/v1/companies/${companyId}/contacts/${id}`;
  const method = 'delete';
  const data = { };

  return api[method](url, data, { withCredentials: true })
    .then((res) => {
      return res.data;
    })
    .catch(() => returnError());
}

export function getCompanyActivityList() {
  const url = '/api/v1/companies/follow_up.json';
  return api.get(url, { withCredentials: true })
    .then((res) => {
      return res.data.companies;
    })
    .catch((error) => {
      console.log(error);
      return returnError();
    });
}

export function saveCompanyDocument(companyId, data) {
  const url = `/api/v1/companies/${companyId}/company_documents`;
  const method = 'post';

  return api[method](url, data, { withCredentials: true })
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      return returnError();
    });
}

export function deleteCompanyDocument(companyId, companyDocumentId) {
  const url = `/api/v1/companies/${companyId}/company_documents/${companyDocumentId}`;
  const method = 'delete';

  return api[method](url, null, { withCredentials: true })
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      return returnError();
    });
}

export function getSalesItemsAndYears(companyId) {
  const url = `/api/v1/companies/${companyId}/sales_histories/item.json`;

  return api.get(url, { withCredentials: true })
    .then((res) => { return res.data; })
    .catch((error) => {
      console.log(error);
      return returnError();
    });
}

export function getSalesHistory(companyId, products, years, reportType) {
  const url = `/api/v1/companies/${companyId}/sales_histories/company_item`;

  let data = {
    years,
    items: products,
    report_type: reportType,
  };

  return api.post(url, data, { withCredentials: true })
    .then((res) => { return res.data; })
    .catch((error) => {
      console.log(error);
      return returnError();
    })
}

export function saveAddress({companyId, data}) {
  const url = `/api/v1/companies/${companyId}/addresses`;

  return api.post(url, data, { withCredentials: true })
    .then((res) => { return res.data; })
    .catch((error) => {
      console.log(error);
      return returnError();
    })
}


// COMPANY SOURCES

export function getCompanySources(inactive) {
  if (inactive === undefined) { inactive = false; }
  const url = '/api/v1/company_sources.json?inactive=' + inactive;
  return api.get(url, { withCredentials: true })
    .then((res) => { return res.data; })
    .catch((error) => {
      console.log(error);
      return returnError();
    });
}

export function saveCompanySource(data) {
  let url = '/api/v1/company_sources'
  let method = 'post'

  if (data.id !== undefined) {
    url += `/${data.id}`
    method = 'put'
  }
  url += '.json'

  return api[method](url, data, { withCredentials: true })
    .then((res) => { return res.data; })
    .catch((error) => {
      console.log(error);
      return returnError();
    });
}

export function deleteCompanySource(id) {
  const url = `/api/v1/company_sources/${id}.json`;
  const method = 'delete';

  return api[method](url, null, { withCredentials: true })
    .then((res) => { return res.data; })
    .catch((error) => {
      console.log(error);
      return returnError();
    });
}