import React, { useState, useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
  Alert,
  Input,
  FormGroup,
  Label,
} from 'reactstrap';
import { DateTimePicker } from 'react-widgets';

// icons
import CheckIcon from 'mdi-react/CheckIcon';

// components
import Address from './Address';

// utils
import { numberToCurrency, splitLines } from '../../libs/utils'

// api
import { saveQuote } from '../../api/quote';
import { decode_message_object } from '../../libs/editor';

// styles
import styles from './QuoteReview.module.scss';

// vars
const MAX_DATE = new Date(8640000000000);

const QuoteReview = ({ id, quote, company, relatedCompany, contact, lineItems, salesRep, paymentOptions, message, open, toggle }) => {
  const navigate = useNavigate()

  const [saving, setSaving] = useState(false)
  const [errors, setErrors] = useState([])

  const [isTaskOpen, setIsTaskOpen] = useState(false)
  const [task, setTask] = useState({
    id: null,
    name: "Follow Up for Quote NUMBER",
    description: "Follow Up with the customer in regards to the quote.",
    due_date: new Date(),
  });
  const [taskModified, setTaskModified] = useState(false)

  useEffect(() => {
    if (!quote.task) return

    setTask({
      id: quote.task.id,
      name: quote.task.name,
      description: quote.task.description,
      due_date: new Date(quote.task.due_date),
    })
  }, [quote])

  // data
  const setMaxDate = () => {
    if (quote.task) {
      return new Date(quote.task.due_date).setDate(new Date().getDate() + 45)
    }
    return new Date().setDate(new Date().getDate() + 45)
  }

  const setMinDate = () => {
    if (quote.task) {
      return new Date(quote.task.due_date)
    }
    return new Date()
  }

  // Actions

  const toggleTask = () => {
    setIsTaskOpen(!isTaskOpen)
  }
  
  const doSaveQuote = (event, status) => {
    event.preventDefault()
    setSaving(true);

    let data = {
      id: id,
      company_id: company.id,
      contact_id: contact.id,
      sales_rep_id: salesRep.id,
      payment_options: paymentOptions.map(option => option.value),
      message,
      status,
      billing_address_id: quote.billingAddress.id,
      shipping_address_id: quote.shippingAddress.id,
      shipping_total: quote.shippingTotal,
      tax_total: quote.taxTotal,
      tax_rate: quote.taxRate,
      tax_region: quote.taxRegion,
      willcall: quote.willCall,
      related_company_id: relatedCompany ? relatedCompany.id : null,
      notes: decode_message_object(quote.notes),
      internal_notes: quote.internalNotes,
      project_name: quote.projectName,
      quote_no_cc_fee: quote.quote_no_cc_fee,
      company_no_cc_fee: quote.company_no_cc_fee,
    }

    if (taskModified) {
      data.task = {
        id: task.id,
        name: task.name,
        description: task.description,
        due_date: task.due_date,
      }
    }

    // build line items
    data.line_items = lineItems.map(item => {
      return {
        id: item.line_item_id,
        item_id: item.id,
        quantity: item.quantity,
        price: item.price,
        discount: item.discount,
        discount_type: item.discount_type
      }
    })

    console.log('data', data)

    saveQuote(data)
      .then((resData) => {
        console.log('resData', resData)
        if (resData.errors && resData.errors.length > 0) {
          setErrors(resData.errors.map(error => error.message))
          setSaving(false)
        } else {
          navigate('/quote');
        }
      })
  }

  const updateTask = (name, value) => {
    setTask({...task, [name]: value })
    setTaskModified(true)
  }

  const updateTaskDueDate = (date) => {
    setTask({...task, due_date: date })
    setTaskModified(true)
  } 

  // Renders

  const renderLineItems = () => {
    return lineItems.map((item, idx) => (
      <div key={idx}>
        <Row className="mb-2">
          <Col xs={12} md={6}>
            {item.sku}
            {' - '}
            {item.name}
          </Col>
          <Col xs={12} md={2}>
            {item.quantity}
          </Col>
          <Col xs={12} md={2} className="text-end">
            {numberToCurrency(item.price)}
          </Col>
          <Col xs={12} md={2} className="text-end">{numberToCurrency(item.total_price)}</Col>          
        </Row>
        <hr />
      </div>
    ))
  }

  const renderLineItemsHeader = () => {
    return (
      <>
        <Row className="mb-2">
          <Col xs={12} md={6} className={styles.rowHeader}>
            Sku / Name
          </Col>
          <Col xs={12} md={2} className={styles.rowHeader}>
            Quantity
          </Col>
          <Col xs={12} md={2} className={styles.rowHeaderEnd}>
            Item Price
          </Col>
          <Col xs={12} md={2} className={styles.rowHeaderEnd}>
            Total
          </Col>
        </Row>
        <hr />
      </>
    )
  }

  const renderLineItemFooter = () => {
    return (
      <>
        <Row>
          <Col xs={12} md={6}>&nbsp;</Col>
          <Col xs={12} md={2}>&nbsp;</Col>
          <Col xs={12} md={2} className={styles.rowFooter}>
            Tax:
            {quote.taxRate !== 0 && (
              <>
                {' '}
                {100 * quote.taxRate}%
                {' - '}
                {quote.taxRegion}
              </>
            )}
          </Col>
          <Col xs={12} md={2} className={styles.rowFooter}>
            {numberToCurrency(quote.taxTotal)}
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={6}>&nbsp;</Col>
          <Col xs={12} md={2}>&nbsp;</Col>
          <Col xs={12} md={2} className={styles.rowFooter}>Shipping:</Col>
          <Col xs={12} md={2} className={styles.rowFooter}>
            {numberToCurrency(quote.shippingTotal)}
            {quote.willCall && ' (Will Call)'}
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={6}>&nbsp;</Col>
          <Col xs={12} md={2}>&nbsp;</Col>
          <Col xs={12} md={2} className={styles.rowFooter}>Total:</Col>
          <Col xs={12} md={2} className={styles.rowFooter}>
            {numberToCurrency(lineItems.reduce((total, item) => total + item.total_price, 0) + quote.shippingTotal + quote.taxTotal)}
          </Col>
        </Row>
      </>
    )
  }

  const renderModalForTask = () => {
    return (
      <Modal
        isOpen={isTaskOpen}
        toggle={toggleTask}
        className="modal-lg"
        centered
      >
        <ModalHeader toggle={toggleTask}>Add Task</ModalHeader>
        <ModalBody>
          <p>The Task will be assigned to the Account Executive who is responsible for this customer.</p>
          <hr />
          <Row>
            <Col>
              <FormGroup floating>
                <Input
                  type="text"
                  name="task"
                  id="task"
                  placeholder="Task"
                  onChange={e => updateTask("name", e.target.value)}
                  value={task.name}                  
                />
                <Label for="task">Task Name</Label>
              </FormGroup>
              <p>If the quote does not have a quote number, NUMBER value will automatically be replaced with the actual quote number.</p>
            </Col>
          </Row>
          <Row>
            <Col className="form-group">
              <Label>Due At</Label>
              <DateTimePicker
                placeholder="Select Date and Time..."
                name="due_date"
                value={new Date(task.due_date)}
                min={setMinDate()}
                max={setMaxDate()}
                includeTime
                onChange={value => updateTaskDueDate(value)}
              />              
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup >
                <Label for="description">Description</Label>
                <textarea
                  name="description"
                  id="description"
                  className="form-control"
                  value={task.description}
                  rows="10"
                  onChange={e => updateTask("description", e.target.value)}
                />
              </FormGroup>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <button className="btn btn-secondary" onClick={toggleTask}>Close</button>
        </ModalFooter>
      </Modal>
    )
  }

  if (company === null || contact === null || lineItems === null || salesRep === null || paymentOptions === null || message === null) return (<></>)

  return (
    <Modal
      isOpen={open}
      toggle={toggle}
      fullscreen={true}
      backdrop="static"
    >
      <ModalHeader toggle={toggle}>Review Quote</ModalHeader>
      <ModalBody>
        <Row>
          <Col xs={12} md={4}>
            <h3>Company</h3>
            <p>
              <strong>{company.name}</strong>
              <br />
              {company.address}
              <br />
              {company.city},
              {' '}
              {company.state}
              {' '}
              {company.zip_code}
              <br />
              {company.telephone}
            </p>
            {relatedCompany && (
              <Alert color="info">
                <h6>Related Company</h6>
                <small>
                  {relatedCompany.name}
                </small>
              </Alert>
            )}
          </Col>
          <Col xs={12} md={4}>
            <h3>Contact</h3>
            <p>
              <strong>
                {contact.first_name}
                {' '}
                {contact.last_name}
              </strong>
              <br />
              {contact.title}
              <br />
              {contact.email}
              <br />
              {contact.telephone}
              {' '}
              {contact.telephone_ext}
            </p>
            <h4>Project Information</h4>
            <p>Project Name: {quote.projectName}</p>
          </Col>
          <Col xs={12} md={4}>
            <h3>Account Executive</h3>
            <p>
              <strong>{salesRep.name}</strong>
              <br />
              {salesRep.email}
            </p>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col xs={12} md={4}>
            <h3>Billing Address</h3>
            <Address address={quote.billingAddress} />
          </Col>
          <Col xs={12} md={4}>
            <h3>Shipping Address</h3>
            <Address address={quote.shippingAddress} />
          </Col>
          <Col xs={12} md={4}>
            <h3>Payment Terms</h3>
            {paymentOptions.map((option, idx) => (
              option !== undefined && (
                <div key={idx}>
                  <CheckIcon size={14} />
                  {' '}
                  {option.label}
                </div>
              )
            ))}
            {quote.quote_no_cc_fee && (
              <>
                <br />
                <div>
                  <CheckIcon size={14} />
                  {' '}
                  No Credit Card Fees for this Quote
                </div>
              </>
            )}
          </Col>
        </Row>
        <hr />
        <Row>
          <h3>Items</h3>
        </Row>
        {renderLineItemsHeader()}
        {renderLineItems()}
        {renderLineItemFooter()}
        <hr />

        {quote.notes !== undefined && quote.notes !== '' && (
          <>
            <h4>Notes:</h4>
            <div dangerouslySetInnerHTML={{ __html: decode_message_object(quote.notes) }} />
          </>
        )}

        {message !== undefined && message !== '' && (
          <>
            <h3>Message</h3>
            <Row>
              <Col>{splitLines(message)}</Col>
            </Row>
          </>
        )}
        {renderModalForTask()}
      </ModalBody>
      <ModalFooter className={styles.actionFooter}>
        {errors.length > 0 && (
          <Row>
            <Col>
              <Alert color="danger">
                {errors}
              </Alert>
            </Col>
          </Row>
        )}
        <Row>
          {saving ? (
            <Col className={styles.saving}>
              <Spinner
                color="primary"
                size="sm"
              />
              {' '}
              Saving...
            </Col>
          ) : (
            <>
              <Col className={styles.centerButton}>
                <button
                  className={["btn", "btn-secondary", styles.button].join(' ')}
                  onClick={() => toggle()}
                >
                  Close
                </button>
                <p className={styles.smallText}>
                  Close this window and continue editing the quote.
                </p>
              </Col>
              <Col className={styles.centerButton}>
                {quote.status === 'draft' && (
                  <>
                    <button
                      className={["btn", "btn-secondary", styles.button].join(' ')}
                      onClick={(e) => doSaveQuote(e, "draft")} 
                      >
                      Save (Draft)
                    </button>
                    <p className={styles.smallText}>
                      Save the quote as a draft and return to edit it later.
                    </p>                  
                  </>
                )}
              </Col>
              <Col className={styles.centerButton}>
                <button
                  className={["btn", "btn-secondary", "mb-4", styles.button].join(' ')}
                  onClick={toggleTask}
                >
                  {quote.task ? ("Update Task") : ("Add Task?")}
                </button>
                <br />
                <button
                  className={["btn", "btn-primary", styles.button].join(' ')}
                  onClick={(e) => doSaveQuote(e, "open")}
                >
                  Send to Customer
                </button>
                <p className={styles.smallText}>
                  {contact.first_name} {contact.last_name} ({contact.email}) will receive an email with an encrypted link to view the quote.
                </p>
              </Col>
            </>
          )}
        </Row>
      </ModalFooter>
    </Modal>
  )
}

PropTypes.propTypes = {
  id: PropTypes.number.isRequired,
  company: PropTypes.object.isRequired,
  contact: PropTypes.object.isRequired,
  lineItems: PropTypes.array.isRequired,
  salesRep: PropTypes.object.isRequired,
  paymentOptions: PropTypes.object.isRequired,
  message: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  toggle: PropTypes.func
}

export default QuoteReview;