import React, { useEffect, useState } from 'react';
import {
  Link,
  useParams,
  useNavigate
} from 'react-router-dom';
import {
  Col,
  Row,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Alert,
  FormGroup,
  Input,
  Label
} from 'reactstrap';

import AccountIcon from 'mdi-react/AccountIcon';

import PageHeader from '../../../components/PageHeader';
import CardWrapper from '../../../components/CardWrapper';
import Error from '../../../components/Error';
import Loading from '../../../components/Loading';
import Saving from '../../../components/Saving';

import SalesGoal from './sales_goal';

import { listUserRoles, getUser, saveUser } from '../../../api/user';

import { checkForAbility } from '../../../libs/utils';

const UserForm = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [user, setUser] = useState({
    name: '',
    role: '',
    email: '',
    telephone_number: '',
    cell_phone: '',
    cell_phone_carrier: '',
    opt_in_cell: false,
    as400_ids: [],
    special_abilities: {
      check: false,
      ecommerce: false,
    }
  });
  const [roles, setRoles] = useState([]);
  const [saving, setSaving] = useState(false);
  const [loading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState('1');
  const [errors, setErrors] = useState([]);
  const [imageUpdated, setImageUpdated] = useState(false);

  const userRole = localStorage.getItem('role');

  useEffect(() => {
    listUserRoles()
      .then((data) => {
        setRoles(data);
      });
  }, []);

  useEffect(() => {
    if (id === undefined) {
      setLoading(false);
    } else {
      console.log('id', id)
      getUser(id)
        .then((res) => {
          res.password = '';
          res.password_confirm = '';
          setUser(res);
          setLoading(false);
        })
    }
  }, [id]);

  // processing

  const buildFormData = () => {
    let formData = new FormData();
    formData.append('user[name]', user.name);
    formData.append('user[role]', user.role);
    formData.append('user[email]', user.email);
    formData.append('user[telephone_number]', user.telephone_number);
    formData.append('user[cell_phone]', user.cell_phone);
    formData.append('user[cell_phone_carrier]', user.cell_phone_carrier);
    formData.append('user[opt_in_cell]', user.opt_in_cell);
    formData.append('user[sales_goals_list]', JSON.stringify(user.sales_goals));
    if (userRole === 'admin') {
      formData.append('user[as400_ids]', user.as400_id);
    }
    if (checkForAbility('root', localStorage)) {
      formData.append('user[special_abilities]', JSON.stringify(user.special_abilities));
    }

    if (typeof user.password !== 'undefined') {
      if (user.password !== '' && (user.password === user.password_confirm)) {
        console.log('adding passwords...');
        formData.append('user[password]', user.password);
        formData.append('user[password_confirmation]', user.password_confirm);
      }
    }

    if (imageUpdated) {
      const file = document.getElementById('avatar').files[0];
      formData.append('user[avatar]', file, file.name);
    }
    console.log(formData);

    return formData;
  };

  // actions

  const toggleTab = (tab) => {
    setActiveTab(tab);
  };

  const handleSaveUser = (e) => {
    e.preventDefault();
    setSaving(true);

    saveUser({ id, data: buildFormData() })
      .then((res) => {
        if (res.errors.length > 0) {
          setErrors(res.errors);
          window.scrollTo(0, 0);
        } else {
          navigate('/admin/users');
        }
      })
  };

  const deleteUser = () => {
    return null;
  };

  // updaters

  const updateValue = (event) => {
    const { name, value } = event.target;
    setUser({ ...user, [name]: value });
  };

  const displayImage = (event) => {
    setImageUpdated(true);    
    const file = document.getElementById('avatar').files[0];
    setUser({ ...user, avatar: URL.createObjectURL(file) });
  };

  const updateSalesGoal = (year, goal) => {
    console.log(year);
    let userSalesGoals = user.sales_goals;
    userSalesGoals.filter(x => x.year === year)[0].goal = goal;
    setUser({ ...user, ["sales_goals"]: userSalesGoals });
  };

  const handleUpdateSpecialAbility = (e, ability) => {
    let special_abilities = user.special_abilities;
    special_abilities[ability] = !special_abilities[ability];
    setUser({ ...user, ["special_abilities"]: special_abilities });
  }

  // renders

  const renderLoading = () => {
    return (
      <Loading />
    );
  };

  const renderImage = () => {
    if (user.avatar === '') {
      return null;
    }
    return (
      <div>
        <img src={user.avatar} alt="Avatar" className="img-circle" width={150} height={150} />
      </div>
    );
  };

  const renderNotSalesRep = () => {
    return (
      <Row>
        <Col>
          <Alert color="info">
            This section is for Account Executives only.
          </Alert>
        </Col>
      </Row>
    );
  };

  const renderSalesGoals = () => {
    return (
      <>
      <Row className="pb-2 pt-2 border border-top-0 border-left-0 border-right-0">
        <Col md={4} className="text-right">Year</Col>
        <Col md={4}>Goal</Col>
      </Row>
      {user.sales_goals.map(item => (
        <SalesGoal salesGoal={item} key={item.year} updateSalesGoal={updateSalesGoal} />
      ))}
      </>
    );
  };

  const renderRoles = () => {
    if (roles.length > 0) {
      return roles.map(role => (
        <option value={role.id} key={role.id}>
          {role.name}
        </option>
      ));
    }
    return null;
  };

  const renderForm = () => {
    return (
      <CardWrapper>
        <Error errors={errors} />
        <form className="taskForm form-horizontal">
          <Nav tabs className="mb-4">
            <NavItem>
              <NavLink onClick={() => toggleTab('1')}>
                Profile
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink onClick={() => toggleTab('2')}>
                Sales Goals
              </NavLink>
            </NavItem>
            {checkForAbility('root', localStorage) && (
              <NavItem>
                <NavLink onClick={() => toggleTab('3')}>
                  Special Abilities!
                </NavLink>
              </NavItem>
            )}
          </Nav>

          <TabContent activeTab={activeTab}>
            <TabPane tabId="1">
              <Row>
              <Col xs={12} sm={3}>
                <div className="form-group">
                  <label htmlFor="avatar" className="control-label btn-primary btn">
                    <AccountIcon size={21} />
                    &nbsp;
                    Upload Avatar
                  </label>
                  <p>
                    Image Size: 150 x 150
                  </p>
                  <input
                    type="file"
                    name="avatar"
                    id="avatar"
                    style={{
                      width: 0.1,
                      height: 0.1,
                      opacity: 0,
                      overflow: 'hidden',
                      position: 'absolute',
                      zIndex: -1,
                    }}
                    onChange={e => displayImage(e)}
                  />
                </div>
              </Col>
              <Col xs={12} sm={9}>
                {renderImage()}
              </Col>
            </Row>
              <Row>
                <Col>
                  <h3>Stats</h3>
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <div className="form-group">
                    <label htmlFor="role" className="control-label">Role</label>
                    <select name="role" className="form-control" id="role" value={user.role} onChange={e => updateValue(e)}>
                      {renderRoles()}
                    </select>
                  </div>
                </Col>
                {userRole === 'admin' && (
                  <Col md="2">
                    <div className="form-group">
                      <label htmlFor="as400_id" className="control-label">AS400 ID</label>
                      <input type="text" name="as400_id" className="form-control" id="as400_id" value={user.as400_id} onChange={e => updateValue(e)} />
                    </div>
                  </Col>
                )}
              </Row>
              <Row>
                <Col md={4}>
                  <div className="form-group">
                    <label htmlFor="name">Name: *</label>
                    <input type="text" className="form-control" name="name" id="name" placeholder="Name" onChange={e => updateValue(e)} value={user.name} />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <div className="form-group">
                    <label htmlFor="telephone_number">Telephone:</label>
                    <input type="text" className="form-control" name="telephone_number" id="telephone_number" placeholder="Telephone Number" onChange={e => updateValue(e)} value={user.telephone_number} />
                  </div>
                </Col>
                <Col md={4}>
                  <div className="form-group">
                    <label htmlFor="cell_phone">Cell Phone Number:</label>
                    <input type="text" className="form-control" name="cell_phone" id="cell_phone" placeholder="Cell Phone Number" onChange={e => updateValue(e)} value={user.cell_phone} />
                    <br />
                    <input type="checkbox" className="filled-in" onChange={e => updateValue(e)} name="opt_in_cell" id="opt_in_cell" checked={user.opt_in_cell} />
                    &nbsp;
                    <label htmlFor="opt_in_cell" className="control-label">Opt In to receive URGENT messages</label>
                  </div>
                </Col>
                <Col md={4}>
                  <div className="form-group">
                    <label htmlFor="carrier">Carrier:</label>
                    <select name="cell_phone_carrier" value={user.cell_phone_carrier} className="form-control" onChange={e => updateValue(e)}>
                      <option>-- SELECT --</option>
                      <option value="AT&T">AT&amp;T</option>
                      <option value="Verizon">Verizon</option>
                      <option value="Sprint">Sprint</option>
                      <option value="T-Mobile">T-Mobile</option>
                    </select>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <h3>Login</h3>
                  <Alert color="info">
                    If <strong>Password</strong> is left blank will not be updated.
                  </Alert>
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <div className="form-group">
                    <label htmlFor="email" className="control-label">Email Address: *</label>
                    <input type="text" className="form-control" name="email" id="email" placeholder="Email Address" onChange={e => updateValue(e)} value={user.email} />
                  </div>
                </Col>
                <Col md={4}>
                  <div className="form-group">
                    <label htmlFor="password" className="control-label">Password</label>
                    <input type="password" placeholder="********" className="form-control" name="password" onChange={e => updateValue(e)} />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  &nbsp;
                </Col>
                <Col md={4}>
                  <div className="form-group">
                    <label htmlFor="password_confirm" className="control-label">Password Confirmation</label>
                    <input type="password" placeholder="********" className="form-control" name="password_confirm" onChange={e => updateValue(e)} />
                  </div>
                </Col>
                <Col md={4}>
                  
                </Col>
              </Row>
            </TabPane>
            <TabPane tabId="2">
              {user.role === 'sales_rep' ? renderSalesGoals() : renderNotSalesRep()}
            </TabPane>
            {(checkForAbility('root', localStorage) || checkForAbility('admin', localStorage)) && (
              <TabPane tabId="3">
                <Row className="mb-4">
                  <Col>
                    <FormGroup switch>
                      <Input
                        type="switch"
                        checked={user.special_abilities.check}
                        onClick={(e) => handleUpdateSpecialAbility(e, 'check')}
                        id="check"
                      />
                      <Label for="check">
                        Can Process Check Payments?
                      </Label>
                    </FormGroup>
                  </Col>
                </Row>
                <Row className="mb-4">
                  <Col>
                    <FormGroup switch>
                      <Input
                        type="switch"
                        checked={user.special_abilities.ecommerce}
                        onClick={(e) => handleUpdateSpecialAbility(e, 'ecommerce')}
                        id="ecommerce"
                      />
                      <Label for="ecommerce">
                        Extended Ecommerce Access? Allows the user to see the MAP reporting feature.
                      </Label>
                    </FormGroup>
                  </Col>
                </Row>
                <Row className="mb-4">
                  <Col>
                    <FormGroup switch>
                      <Input
                        type="switch"
                        checked={user.special_abilities.quotes_no_cc_fee}
                        onClick={(e) => handleUpdateSpecialAbility(e, 'quotes_no_cc_fee')}
                        id="quotes_no_cc_fee"
                      />
                      <Label for="quotes_no_cc_fee">
                        Has the ability to set <strong>NO CREDIT CARD FEE</strong> for quotes. For the quote this is associated with the Sales Rep.
                      </Label>
                    </FormGroup>
                  </Col>
                </Row>
                <Row className="mb-4">
                  <Col>
                    <FormGroup switch>
                      <Input
                        type="switch"
                        checked={user.special_abilities.root}
                        onClick={(e) => handleUpdateSpecialAbility(e, 'root')}
                        id="root"
                      />
                      <Label for="root">
                        Admin Special Access (Ability to see this Tab) <strong>DO NOT GIVE THIS ABILITY TO NON-ADMINS</strong>
                      </Label>
                    </FormGroup>
                  </Col>
                </Row>
              </TabPane>
            )}
          </TabContent>
          {saving && (
            <Saving />
          )}
          <Row className="mt-4">
            <Col className="text-center">
              <input type="submit" value="save" className="btn btn-primary" onClick={e => handleSaveUser(e)} />
              &nbsp;
              <Link to="/admin/users" className="btn btn-secondary">
                Cancel
              </Link>
            </Col>
          </Row>
        </form>
      </CardWrapper>
    );
  };

  return (
    <>
      <PageHeader title="Edit User" />
      {loading ? renderLoading() : renderForm()}
    </>
  );
}


export default UserForm
