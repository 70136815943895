// add/edit quote
import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom'
import {
  Row,
  Col,
  Badge,
  Button,
  Alert,
  Modal,
  ModalHeader,
  ModalBody,
  Input,
  Label,
  FormGroup,
} from 'reactstrap'
import {
  DropdownList,
  Multiselect
} from 'react-widgets'

import { Editor } from 'react-draft-wysiwyg';
import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

// icons
import PlusIcon from 'mdi-react/PlusIcon'
import CheckIcon from 'mdi-react/CheckIcon';
import FilePdfBoxIcon from 'mdi-react/FilePdfBoxIcon';

// components
import PageHeader from '../../components/PageHeader'
import PageSubHeader from '../../components/PageSubHeader'
import CardWrapper from '../../components/CardWrapper'
import AlertBlock from '../../components/AlertBlock'
import Error from '../../components/Error'

// quote components
import LineItems from '../../components/quote/LineItems'
import QuoteReview from '../../components/quote/QuoteReview'
import AddressSelector from '../../components/quote/AddressSelector'
import Address from '../../components/quote/Address'
import Message from '../../components/quote/Message'
import Shipping from '../../components/quote/Shipping'
import Tax from '../../components/quote/Tax'
import History from '../../components/quote/History'

// utils
import {
  paymentOptionsList,
  paymentOptionsList2,
  splitLines,
  numberToCurrency,
  currentId,
  inValidRole,
  checkForAbility,
  willCallAddress
} from '../../libs/utils'
import {
  validateQuote,
  inQuoteStatus,
  findQuoteStatus
} from '../../libs/quote'
import {
  editor_toolbar,
  decode_message_object,
  encode_message_object }
from '../../libs/editor';

// styles
import styles from './Quote.module.scss'

// api
import { getCompany } from '../../api/company'
import { getItems } from '../../api/item'
import { fullUserListByRole } from '../../api/user'
import { listCompaniesForDropDown } from '../../api/company';
import {
  getQuote,
  deleteQuote,
  statusUpdate,
  saveQuoteNote,
} from '../../api/quote';
import Moment from 'react-moment';

const QuoteEdit = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const taxRef = useRef()
  const pageTitle = 'Update'

  // state
  const [loading, setLoading] = useState(true)
  const [quote, setQuote] = useState({
    url: null,
    company: null,
    contact: null,
    status: 'draft',
    paymentStatus: '',
    processStatus: 'unprocessed',
    poNumber: '',
    payment_option_selected: '',
    salesRep: null,
    segment: null,
    createdBy: null,
    linesItems: [],
    billingAddress: {},
    shippingAddress: {},
    messages: [],
    message: "",
    shippingTotal: 0,
    freeShipping: false,
    willCall: false,
    taxTotal: 0,
    taxRegion: '',
    taxRate: 0,
    total: 0,
    grandTotal: 0,
    quote_number: null,
    history: [],
    created: { name: '', id: '', date: null },
    payment: null,
    doCalculate: 0,
    taxCalculated: false,
    notes: encode_message_object(''),
    internalNotes: '',
    pdf: '',
    task: null,
    quote_no_cc_fee: false,
    company_no_cc_fee: false,
  })
  const [steps, setSteps] = useState({
    company: true,
    contact: true,
    salesRep: true,
    lineItems: true,
    payment: true,
  })
  const [company, setCompany] = useState(null)
  const [addresses, setAddresses] = useState([])
  // const [contacts, setContacts] = useState([])
  const [contact, setContact] = useState(null)
  // const [salesReps, setSalesReps] = useState([])
  const [salesRep, setSalesRep] = useState(null)
  const [lineItems, setLineItems] = useState([])
  const [paymentOptions, setPaymentOptions] = useState([])
  const [message, setMessage] = useState("")

  const [companies, setCompanies] = useState([])
  const [relatedCompany, setRelatedCompany] = useState(null)

  const [clearLineItems, setClearLineItems] = useState(false)
  const [forceUpdateOfLineItems, setForceUpdateOfLineItems] = useState(false)
  const [valid, setValid] = useState(false)
  const [errors, setErrors] = useState([])

  const [isQuoteReviewOpen, setIsQuoteReviewOpen] = useState(false)

  const [savingNotes, setSavingNotes] = useState(false)
  const [savingNotesAlert, setSavingNotesAlert] = useState('')

  const [isOpenPaymentStatusUpdateModal, setIsOpenPaymentStatusUpdateModal] = useState(false)

  const [quoteNoCcFieldDisabled, setQuoteNoCcFieldDisabled] = useState(false)
  const [companyNoCcFieldDisabled, setCompanyNoCcFieldDisabled] = useState(false)

  const [c, setC] = useState(0)

  useEffect(() => {
    console.log('id', id)

    if (id !== undefined) {
      listCompaniesForDropDown()
        .then(data => {
          setCompanies(data.companies)
        })
      getQuote(id)
        .then(d => {
          console.log('quote', d.quote)
          
          setSalesRep(d.quote.sales_rep)
          setQuote(prevQuote => ({
            ...prevQuote,
            url: d.quote.url,
            status: d.quote.status,
            processStatus: d.quote.processStatus,
            paymentStatus: d.quote.paymentStatus,
            quote_number: d.quote.quote_number, 
            poNumber: d.quote.po_number,
            payment_option_selected: d.quote.payment_option_selected,
            billingAddress: d.quote.billingAddress, 
            shippingAddress: d.quote.shippingAddress,
            messages: d.quote.messages,
            history: d.quote.history,
            total: d.quote.total,
            taxTotal: d.quote.taxTotal,
            taxRate: d.quote.taxRate,
            taxRegion: d.quote.taxRegion,
            shippingTotal: d.quote.shippingTotal,
            grandTotal: d.quote.grandTotal,
            willCall: d.quote.willcall,
            created: d.quote.created,
            payment: d.quote.payment,
            taxCalculated: true,
            notes: encode_message_object(d.quote.notes),
            internalNotes: d.quote.internal_notes,
            pdf: d.quote.pdf_path,
            projectName: d.quote.projectName,
            task: d.quote.task,
            quote_no_cc_fee: d.quote.quote_no_cc_fee,
            company_no_cc_fee: d.quote.company_no_cc_fee,
          }))
          setRelatedCompany(d.quote.relatedCompany || null)
          setMessage(d.quote.message)
          setCompany(d.quote.company)
          setContact(d.quote.contact)
          setLineItems(d.quote.line_items)

          setQuoteNoCcFieldDisabled(d.quote.company_no_cc_fee)
          setCompanyNoCcFieldDisabled(d.quote.company_no_cc_fee)

          loadCompanyData(d.quote.company.id, d.quote.payment_options)
          
          setTimeout(() => {
            setQuote(prevQuote => ({
              ...prevQuote,
              doCalculate: prevQuote.doCalculate + 1
            }))
            
            // calculateTotals(d.quote.line_items, d.quote.shippingTotal, d.quote.taxTotal)
            setLoading(false)
          }, 1000)
        })
    }
  }, [id])

  useEffect(() => {
    calculateTotals()
  }, [quote.doCalculate, lineItems, quote.taxTotal, quote.shippingTotal])

  const taxCalcalateStatus = (tempQuote) => {
    if (tempQuote.taxTotal === 0 && tempQuote.taxRate === 0 && tempQuote.taxRegion === '') { return false }
  }

  const showBlock = () => {
    if (quote.status === 'declined') { return false }
    if (quote.status === 'approved') { return false }
    return true
  }

  const parsePaymentOptions = (list, terms) => {
    console.log('list', list)
    const options = paymentOptionsList2(terms)
    console.log('options', options)
    const data = list.map((item) => {
      return options.find((option) => option.value === item)
    })
    console.log('data', data)
    return data
  }

  // actions
  const loadCompanyData = (companyId, tempPaymentOptions) => {
    getCompany(companyId)
      .then(d => {
        // setContacts(d.contacts)
        setCompany(d)
        setAddresses(d.addresses)
        setPaymentOptions(parsePaymentOptions(tempPaymentOptions, d.terms))
      })
  }

  const handleDeleteQuote = (e) => {
    e.preventDefault()
    if (window.confirm("Are you sure you wish to delete this quote?\n\nThis cannot be undone")) {
      if (id !== undefined) {
        deleteQuote(id)
          .then(() => {
            navigate('/quote')
          })
      }
    }
  }

  const updateLineItems = (data) => {
    setLineItems(data)
    setC(c + 1)

    setQuote(prevQuote => ({
      ...prevQuote,
      taxCalculated: false,
      doCalculate: prevQuote.doCalculate + 1
    }))
  }

  const updatePaymentOptions = (data) => {
    setPaymentOptions(data)
  }

  const updateMessage = (value) => {
    setMessage(value)
  }

  const updateClearLineItems = (value) => {
    setClearLineItems(false)
  }

  const updateProjectName = (value) => {
    setQuote(prevQuote => ({ ...prevQuote, projectName: value }))
  }

  const handleUpdateNotes = (content, editor) => {
    setQuote({ ...quote, notes: content })
  }

  const handleUpdateInternalNotes = (value) => {
    setQuote({ ...quote, internalNotes: value })
  }

  const toggleQuoteReview = () => {
    console.log('taxRef', taxRef)
    taxRef.current.verifyTax()

    setTimeout(() => {
      const validate = validateQuote({ quote, paymentOptions, lineItemCount: lineItems.length })
      if (validate.errors.length === 0) {
        setIsQuoteReviewOpen(!isQuoteReviewOpen)
        setErrors([])
      } else {
        setErrors(validate.errors)
      }
    }, 300)
  }

  const handleSaveNotes = (e) => {
    e.preventDefault()
    setSavingNotes(true)

    let data = {
      id: id,
      internal_notes: quote.internalNotes,
    }

    console.log('data', data)

    saveQuoteNote(data)
      .then((resData) => {
        if (resData.errors && resData.errors.length > 0) {
          setErrors(resData.errors.map(error => error.message))
        } else {
          setSavingNotesAlert("Notes Saved")
        }
        setSavingNotes(false)
      })
  }

  const updateBillingAddress = (address, reloadCompany) => {
    console.log('updateShippingAddress =>', reloadCompany)
    setQuote({ ...quote, billingAddress: address })
    if (reloadCompany) { loadCompanyData(company.id) }
  }

  const updateShippingAddress = (address, reloadCompany) => {
    console.log('updateShippingAddress =>', reloadCompany)
    setQuote({ ...quote, shippingAddress: address, taxCalculated: false })
    if (reloadCompany) { loadCompanyData(company.id) }
  }

  const calculateTotals = () => {
    const { taxTotal, shippingTotal } = quote

    console.log('taxTotal', taxTotal)

    let _total = 0
    let _grand_total = 0
    if (lineItems.length > 0) {
      _total = lineItems.reduce((t, item) => t + item.total_price, 0)
    }
    _grand_total = _total + shippingTotal + taxTotal
    console.log("Total", _total)
    setQuote(prevQuote => ({...prevQuote, grandTotal: _grand_total, total: _total}))
  }

  const handleShippingTotalUpdate = ({shipping, freeShipping}) => {
    setQuote(prevQuote => ({
      ...prevQuote,
      shippingTotal: shipping,
      freeShipping: freeShipping,
      doCalculate: prevQuote.doCalculate + 1
    }))
  }

  const handleTaxUpdate = ({ total_tax, tax_region, tax_rate}) => {
    setQuote(prevQuote => ({
      ...prevQuote,
      taxTotal: total_tax,
      taxRegion: tax_region,
      taxRate: tax_rate,
      taxCalculated: true,
      doCalculate: prevQuote.doCalculate + 1
    }))
  }

  const handleWillCallToggle = () => {
    const { willCall, shippingTotal } = quote
    let newShippingTotal = 0
    let newWillCall = !willCall

    setQuote(prevQuote => ({
      ...prevQuote,
      willCall: newWillCall,
      shippingTotal: newWillCall ? newShippingTotal : shippingTotal,
      shippingAddress: newWillCall ? willCallAddress() : {},
      doCalculate: prevQuote.doCalculate + 1
    }))
  }

  const handleCreditCardFee = (value, field) => {
    if (field === 'company_no_cc_fee') {
      if (!quote.company_no_cc_fee) {
        setQuote(prevQuote => ({
          ...prevQuote,
          quote_no_cc_fee: true,
          doCalculate: prevQuote.doCalculate + 1
        }))
        setQuoteNoCcFieldDisabled(true)
      } else {
        setQuoteNoCcFieldDisabled(false)
      }
    }

    setQuote(prevQuote => ({
      ...prevQuote,
      [field]: !quote[field],
      doCalculate: prevQuote.doCalculate + 1
    }))    
  }

  const markAsProcessed = (e, value) => {
    e.preventDefault()

    const data ={ 
      id,
      item: 'process_status',
      value,
    }

    // 
    statusUpdate(data)
      .then((resData) => {
        if (resData.errors && resData.errors.length > 0) {
          setErrors(resData.errors.map(error => error.message))
        } else {
          setQuote({...quote, processStatus: value})
        }
      })
  }

  const markAsPaid = (e) => {
    e.preventDefault()

    const data = {
      id,
      item: 'payment_status',
      value: 'paid',
    }

    statusUpdate(data)
      .then((resData) => {
        if (resData.error && resData.errors.length > 0) {
          setErrors(resData.errors.map(error => error.message))
        } else {
          setQuote({...quote, paymentStatus: 'paid'})
          setIsOpenPaymentStatusUpdateModal(false)
        }
      })
  }

  const togglePaymentStatusUpdateModal = () => {
    setIsOpenPaymentStatusUpdateModal(!isOpenPaymentStatusUpdateModal)
  }

  // renders
  const renderCompanyBlock = () => {
    return (
      <CardWrapper
        key="companyWrapper"
        id="companyWrapper"
        header="Company"
      >
        {renderCompanyInfo()}
        {renderRelatedCompanyBlock()}
      </CardWrapper>
    )
  }

  const renderCompanyInfo = () => {
    if (company === null) { return null }
    return (
      <p>
        <strong>{company.name}</strong>
        {(company.as400_id !== '' && company.as400_id !== null && company.as400_id !== '-1') && ` (${company.as400_id})`}
        <br />
        {company.address}
        <br />
        {company.city},
        {' '}
        {company.state}
        {' '}
        {company.zip_code}
        <br />
        {company.telephone}
      </p>
    )
  }

  const renderContactBlock = () => {
    return (
      <CardWrapper
        key="contactWrapper"
        id="contactWrapper"
        header="Contact"
        className={renderClassForSection('contact')}
      >
        {renderContactInfo()}
      </CardWrapper>
    )
  }

  const renderContactInfo = () => {
    if (!steps.contact || contact === null) { return null }

    return (
      <p>
        <strong>
          {contact.first_name}
          {' '}
          {contact.last_name}
        </strong>
        <br />
        {contact.title}
        <br />
        {contact.email}
        <br />
        {contact.telephone}
        {' '}
        {contact.telephone_ext}
      </p>
    )
  }

  const renderProjectInfo = () => {
    return (
      <CardWrapper
        key="projectWrapper"
        id="projectWrapper"
        header="Project Information"
        className={renderClassForSection('salesRep')}
      >
        <Row>
          <Col>
            {['approved', 'declined', 'expired'].indexOf(quote.status) > -1 ? (
              <>
                {quote.projectName ? 'N/A' : 'Project Name: N/A' }
              </>
            ) : (            
              <FormGroup floating>
                <Input
                  name="projectName"
                  id="projectName"
                  type="text"
                  className="form-control"
                  placeholder="Project Name"
                  value={quote.projectName}
                  onChange={e => updateProjectName(e.target.value)}
                  />
                <Label>Project Name</Label>
              </FormGroup>
            )}
          </Col>
        </Row>
      </CardWrapper>
    )
  }

  const renderSalesRepBlock = () => {
    return (
      <CardWrapper
        key="salesRepWrapper"
        id="salesRepWrapper"
        header="Account Executive"
        className={renderClassForSection('salesRep')}
      >
        {renderSalesRep()}
      </CardWrapper>
    )
  }

  const renderSalesRep = () => {
    if (company === null) { return null }
    if (salesRep !== null) {
      return (
        <p>
          <strong>{salesRep.name}</strong>
          <br />
          {salesRep.email}
        </p>
      )
    }
  }

  // related company for those w/o AS400 ID value (Cash Accounts mainly)
  const renderRelatedCompanyBlock = () => {
    // if (company === null) { return null }
    // if (company.as400_id !== null || company.as400_id === '-1') { return null }
    // if (company.as400_id > 0) { return null }
    if (relatedCompany === undefined || relatedCompany === null) { return null }
    
    return (
      <>
        <hr />
        <h5 className={styles.cashAccountHeader}>
          Company associated with a <span>CASH ACCOUNT</span>?
        </h5>
        <p>
          {relatedCompany ? relatedCompany.name : 'N/A'}
        </p>

        {/* <DropdownList
          placeholder='Company'
          data={companies.filter(c => (c.as400_id !== null && c.as400_id !== 0 && c.as400_id !== -1))}
          textField='name'
          valueField='id'
          value={relatedCompany}
          onChange={value => handleRelatedCompanySelected(value)}
        /> */}

      </>
    )
  }

  const renderClassForSection = (section) => {
    if (steps[section]) {
      return styles.active
    }
    return styles.inactive
  }

  // shipping - set the price for this
  const renderShipping = () => {
    return (
      <Shipping
        shippingCost={quote.shippingTotal}
        willcall={quote.willCall}
        quoteStatus={quote.status}
        updateData={(data) => handleShippingTotalUpdate(data)}
      />
    )
  }
  
  const renderCreditCardFee = () => {
    if (quote.payment === null) { return null }
    if (quote.payment.creditCardFee === 0) { return null }
    if (quote.payment_option_selected !== "creditCard") { return null }

    return (
      <Row className="mt-2">
        <Col xs={12} md={5}>&nbsp;</Col>
        <Col xs={12} md={2}>&nbsp;</Col>
        <Col xs={12} md={2} className="text-end">Credit Card Fee:</Col>
        <Col xs={12} md={2} className="text-end">
          {numberToCurrency(quote.payment.creditCardFee)}
        </Col>
      </Row>
    )
  }

  const renderGrandTotal = () => {
    let fee = 0;
    if (quote.payment !== null && quote.payment_option_selected === "creditCard") { 
      fee = quote.payment.creditCardFee
    }

    return (
      <>
        <hr />
        <Row>
          <Col xs={12} md={5}>&nbsp;</Col>
          <Col xs={12} md={2}>&nbsp;</Col>
          <Col xs={12} md={2} className="text-end">Grand Total:</Col>
          <Col xs={12} md={2} className="text-end">
            {numberToCurrency(quote.grandTotal + fee)}
          </Col>
        </Row>
      </>
    )
  }

  const renderTax = () => {
    return (
      <Tax
        total={0}
        quote={quote}
        quoteId={id}
        company={company}
        updateData={(data) => handleTaxUpdate(data)}
        ref={taxRef}
      />
    )
  }

  const renderApprovedButtonOptions = () => {
    const { status, paymentStatus, processStatus } = quote

    if (status !== "approved") { return null }
    // if (processStatus === "processed") { return null }

    if (paymentStatus === "pending") {
      return (
        <div class="me-4">
          <Button color="warning" disabled={true}>
            Cannot be processed until payment has been cleared.
          </Button>
        </div>
      )
    }

    return (
      <div className="me-4">
        <button
          className="btn btn-secondary"
          onClick={e => markAsProcessed(e, quote.processStatus === "unprocessed" ? "processed" : "unprocessed")}
        >
          <CheckIcon size={16} className="me-1" />
          {quote.processStatus === "unprocessed" ? "Mark Quote as Processed" : "Quote Processed - Undo"}
        </button>
      </div>
    )
  }

  const renderPaymentInformation = () => {
    if (quote.payment === null) { return null }

    let list = []

    const { authorizationCode, lastFour, nameOnCard, expirationDate, creditCardFee, paymentType, accountType, nameOnAccount } = quote.payment

    if (paymentType === 'ach') {
      list.push({ label: 'Payment Method', value: 'ACH' })
      list.push({ label: 'Authorization Code', value: authorizationCode })
      list.push({ label: 'Name on Account', value: nameOnAccount })
      list.push({ label: 'Account Type', value: accountType.toUpperCase() })
    } else if (paymentType === 'credit_card') {
      list.push({ label: 'Payment Method', value: 'Credit Card' })
      list.push({ label: 'Authorization Code', value: authorizationCode })
      list.push({ label: 'Fee', value: numberToCurrency(creditCardFee) })
      list.push({ label: 'Name on Card', value: nameOnCard })
      list.push({ label: 'Last Four of Card Number', value: lastFour })
      list.push({ label: 'Expiration Date', value: expirationDate })
    }
    
    if (list.length === 0) { return null }
    
    return (
      <>
        <h4>Payment</h4>
        {list.map((item) => (
          <Row key={item.label}>
            <Col xs={12} md={6}>
              {item.label}:
            </Col>
            <Col xs={12} md={6} className={styles.bold}>
              {item.value}
            </Col>
          </Row>
        ))}
      </>
    )
  }

  const renderApprovedInformation = () => {
    if (quote.status !== "approved") { return null }

    const finalStatus = findQuoteStatus(quote.history, 'approved')

    return (
      <Alert
        color="success"
      >
        <h3>Quote Approved Information</h3>
        <hr />
        <Row>
          <Col xs={12} md={6}>
            <Row>
              <Col xs={12} md={6}>
                Payment Status:
              </Col>
              <Col xs={12} md={6} className={styles.bold}>
                {quote.paymentStatus.toUpperCase()}
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={6}>
                Purchase Order Number:
              </Col>
              <Col xs={12} md={6} className={styles.bold}>
                {quote.poNumber}
              </Col>
            </Row>
            {quote.payment_option_selected !== '' && quote.payment_option_selected === "check" && (
              <Row>
                <Col xs={12} md={6}>
                  Selected Payment Option:
                </Col>
                <Col xs={12} md={6} className={styles.bold}>
                  {quote.payment_option_selected.toUpperCase()}
                  {quote.paymentStatus === 'pending' && checkForAbility("check", window.localStorage) && (
                    <button
                      className="btn bg-danger text-white btn-xs ms-2"
                      onClick={() => togglePaymentStatusUpdateModal()}
                    >
                      Update Payment Status
                    </button>
                  )}
                </Col>
              </Row>
            )}
            <Row>
              <Col xs={12} md={6}>
                Approved by:
              </Col>
              <Col xs={12} md={6} className={styles.bold}>
                {finalStatus.user}
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={6}>
                Approved on:
              </Col>
              <Col xs={12} md={6} className={styles.bold}>
                <Moment format="MM/DD/YYYY hh:mm a">{finalStatus.date}</Moment>
              </Col>
            </Row>
          </Col>
          <Col xs={12} md={6}>
            {renderPaymentInformation()}
          </Col>
        </Row>
      </Alert>
    )
  }

  const selectStatusColor = () => {
    switch (quote.status) {
      case 'draft':
        return 'warning'
      case 'approved':
        return 'success'
      case 'declined':
        return 'danger'
      default:
        return 'info'
    }
  }

  const renderPdfLink = () => {
    const { pdf } = quote

    if (pdf === null || pdf === '') { return null }

    return (
      <button
        className="btn btn-primary btn-xs"
        onClick={() => window.open(pdf, '_blank')}
      >
        <FilePdfBoxIcon size={12} className="ms-1" />
        {' '}
        PDF
      </button>
    )
  }
    
  return (
    <>
      <PageHeader title={`Quote: ${pageTitle}`} />

      {loading ? (
        <div>Loading...</div>
      ) : (     
        <>
          <div className={styles.quoteFormBackButton}>
              {(((inValidRole("admin", window.localStorage)) || (quote.sales_rep && currentId(window.localStorage) === quote.sales_rep.id)) && quote.status === "draft") && (
              <div className="me-4">
                <button
                  onClick={e => handleDeleteQuote(e)}
                  className="btn btn-danger"
                >
                  Delete
                </button>
              </div>
            )}

            {renderApprovedButtonOptions()}

            <Message
              quote={quote}
              id={id}
              addNew={true}
            />

            <Link to="/quote" className="btn btn-primary">
              Back to Quotes
            </Link>
          </div>
          <Row>
            <Col>
              <Alert color={selectStatusColor()}>
                <Row>
                  <Col md={6}>
                    Quote Status:
                    {' '}
                    {quote.status.toUpperCase()}
                    {' '}
                    {inQuoteStatus(quote.status, ["open", "approved"]) && (
                      <Badge>
                        Quote Number:
                        {' '}
                        {quote.quote_number}
                      </Badge>
                    )}
                  </Col>
                  <Col md={6} className='text-end'>
                    <Badge>
                      Created by:
                      {' '}
                      {quote.created.name}
                      {' @ '}
                      <Moment format="MM/DD/YYYY">{quote.created.date}</Moment>
                    </Badge>
                    {' '}
                    <History
                      history={quote.history}
                    />
                    {' '}
                    {renderPdfLink()}
                  </Col>
                </Row>
              </Alert>
            </Col>
          </Row>

          {renderApprovedInformation()}

          <Row>
            <Col md={6}>
              {renderCompanyBlock()}
              {renderProjectInfo()}
            </Col>
            <Col md={6}>
              {renderContactBlock()}
              {renderSalesRepBlock()}
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <CardWrapper header="Billing Address">
                  {showBlock() && (
                  <AddressSelector
                    title="Billing Address"
                    updateAddress={updateBillingAddress}
                    addresses={addresses}
                    companyId={company.id}
                    />
                )}
                <Address
                  address={quote.billingAddress}
                  />
              </CardWrapper>
            </Col>
            <Col md={6}>
              <CardWrapper header="Shipping Address">
                  {showBlock() && (
                    <>
                      <FormGroup switch>
                        <Input
                          type="switch"
                          checked={quote.willCall}
                          onClick={() => handleWillCallToggle()}
                        />
                        {' '}
                        Will Call
                      </FormGroup>
                      <hr />
                    
                      {!quote.willCall && (
                        <AddressSelector
                          title="Shipping Address"
                          addresses={addresses}
                          updateAddress={updateShippingAddress}
                          companyId={company.id}
                        />
                      )}
                    </>
                  )}
                  <Address
                    address={quote.shippingAddress}
                  />
                
              </CardWrapper>
            </Col>
          </Row>

          <CardWrapper
            key="lineItemsWrapper"
            id="lineItemsWrapper"
            header="Product/Line Items"
            className={renderClassForSection('lineItems')}
          >
            {steps.lineItems && (
              <LineItems
                updateData={updateLineItems}
                companyId={company.id}
                as400Id={company.as400_id}
                clear={clearLineItems}
                updateClear={updateClearLineItems}
                forceUpdate={forceUpdateOfLineItems}
                lineItems={lineItems}
                quoteStatus={quote.status}
                creditCardFee={quote.creditCardFee}
              />
            )}
            <Row>
              <Col xs={12}><hr /></Col>
            </Row>
            {renderTax()}
            {renderShipping()}
            {renderCreditCardFee()}
            {renderGrandTotal()}
          </CardWrapper>

          <Row>
            <Col md={4}>
              {showBlock() && (
                <CardWrapper
                  key="paymentWrapper"
                  id="paymentWrapper"
                  header="Payment Options"
                  className={renderClassForSection('payment')}
                >
                  {steps.salesRep && showBlock() && (
                    <>
                      <Multiselect
                        data={paymentOptionsList(company)}
                        dataKey="value"
                        textField="label"
                        onChange={value => updatePaymentOptions(value)}
                        value={paymentOptions}
                      />
                      <p className={styles.terms}>
                        Terms on File:
                        {' '}
                        {company.terms || 'N/A'}
                      </p>
                      {salesRep && salesRep.special_abilities.quotes_no_cc_fee && (
                        <>
                          <hr />
                          <h5>Credit Card Fee Options</h5>
                          <FormGroup switch>
                            <Input
                              disabled={quoteNoCcFieldDisabled}
                              type="switch"
                              checked={quote.quote_no_cc_fee}
                              onClick={e => handleCreditCardFee(e, 'quote_no_cc_fee')}
                              id="quote_no_cc_fee"
                            />
                            <Label for="quote_no_cc_fee">
                              No Credit Card Fees for this Quote
                            </Label>
                          </FormGroup>
                          <FormGroup switch>
                            <Input
                              disabled={companyNoCcFieldDisabled}
                              type="switch"
                              checked={quote.company_no_cc_fee}
                              onClick={e => handleCreditCardFee(e, 'company_no_cc_fee')}
                              id="company_no_cc_fee"
                            />
                            <Label for="company_no_cc_fee">
                              No Credit Card Fees for all Quotes for this Company
                            </Label>
                          </FormGroup>
                          {companyNoCcFieldDisabled && (
                            <Alert color="info">
                              Credit Card Fees are disabled for this company.
                            </Alert>
                          )}
                        </>
                      )}
                    </>
                  )}
                </CardWrapper>
              )}

              <CardWrapper
                header="Internal Notes (Not Visible to Customer)"
                headerColorClass="headerGrey"
              >
                {showBlock() ? (
                  <>
                    {steps.salesRep && (
                      <>
                        <Alert color="warning">
                          <textarea
                            className="form-control"
                            rows="10"
                            placeholder="Enter INTERNAL notes here..."
                            value={quote.internalNotes}
                            onChange={e => handleUpdateInternalNotes(e.target.value)}
                          />
                        </Alert>
                        <div className="text-end">
                          <button
                            className="btn btn-primary"
                            onClick={e => handleSaveNotes(e)}
                            disabled={savingNotes}
                          >
                            Save Notes
                          </button>
                        </div>
                        {savingNotesAlert !== '' && (
                          <>
                            {savingNotesAlert}
                          </>
                        )}
                      </>
                    )}
                  </>
                ) : (
                  <>
                    <Alert color="warning">
                      <textarea
                        className="form-control"
                        rows="10"
                        placeholder="Enter INTERNAL notes here..."
                        value={quote.internalNotes}
                        onChange={e => handleUpdateInternalNotes(e.target.value)}
                      />
                    </Alert>
                    <div className="text-end">
                      <button
                        className="btn btn-primary"
                        onClick={e => handleSaveNotes(e)}
                        disabled={savingNotes}
                      >
                        Save
                      </button>
                    </div>
                    {savingNotesAlert !== '' && (
                      <>
                        {savingNotesAlert}
                      </>
                    )}
                  </>
                )}
              </CardWrapper>
            </Col>
              <Col md={8}>
                <CardWrapper
                  key="notesWrapper"
                  id="notesWrapper"
                  header="Notes for Customer"
                >
                  {steps.salesRep && (
                    <>
                      {showBlock() ? (
                        <Alert color="info">
                          <Editor
                            wrapperClassName="card p-2"
                            editorState={quote.notes}
                            onEditorStateChange={(content) => handleUpdateNotes(content)}
                            toolbar={editor_toolbar()}
                          />
                        </Alert>
                      ) : (
                        <div dangerouslySetInnerHTML={{ __html: decode_message_object(quote.notes) }}></div>
                      )}
                    </>
                  )}
                </CardWrapper>
              </Col>
          </Row>
          <Row>
            <Col md={{ size: 4, offset: 8 }} className="text-end">
              {errors.length > 0 && (
                <Error
                  errors={errors}
                  header="Fix the following before saving:"
                />
              )}
              {showBlock()  && (
                <>
                  <br />
                  <button
                    className="btn btn-primary"
                    onClick={e => toggleQuoteReview()}
                  >
                    Save & Review Quote
                  </button>
                </>
              )}
            </Col>
          </Row>
          <QuoteReview
            id={id}
            quote={quote}
            company={company}
            relatedCompany={relatedCompany}
            contact={contact}
            lineItems={lineItems}
            salesRep={salesRep}
            message={message}
            paymentOptions={paymentOptions}
            open={isQuoteReviewOpen}
            toggle={toggleQuoteReview}
          />

          {quote.payment_option_selected === 'check' && (
            <Modal
              isOpen={isOpenPaymentStatusUpdateModal}
              toggle={togglePaymentStatusUpdateModal}
              centered
            >
              <ModalHeader toggle={togglePaymentStatusUpdateModal}>
                Update Payment Status
              </ModalHeader>
              <ModalBody>
                <div className="text-center">
                  Mark this quote as paid?<br /><small>(This cannot be undone)</small>
                </div>

                <Row className="mt-4">
                  <Col className="text-center">
                    <button
                      className="btn btn-primary"
                      onClick={(e) => markAsPaid(e)}
                    >
                      Mark as Paid
                    </button>
                  </Col>
                  <Col className="text-center">
                    <button
                      className="btn btn-secondary"
                      onClick={() => togglePaymentStatusUpdateModal()}
                    >
                      Close - I'll do this later
                    </button>
                  </Col>
                </Row>

              </ModalBody>
            </Modal>
          )}
        </>
      )}
    </>
  )
}

export default QuoteEdit;